var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0 mr-10 ml-10",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xl":"10","lg":"8","cols":"8"}},[_c('p',{staticClass:"page-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('INBOX.GENERAL_TITLE')))]),_c('p',{staticClass:"page-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('INBOX.GENERAL_DESCRIPTION')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mode-header",staticStyle:{"background-color":"#BE5E3B !important"},attrs:{"flat":""}},_vm._l((_vm.toolbar),function(menu,index){return _c('v-toolbar-title',{key:index,staticClass:"cursor-pointer mr-10"},[_c('router-link',{staticClass:"text-decoration-none color-inherit black--text",attrs:{"active-class":"white--text","to":menu.slug}},[_vm._v(_vm._s(_vm.$t('INBOX.TITLE'))+" ")])],1)}),1),_c('div',[_c('v-main',[_c('v-data-table',{key:_vm.reloadTable,staticClass:"table__minimal inbox-email elevation-0",attrs:{"loading":_vm.dataTableLoading,"items":_vm.getEmails,"headers":_vm.headers,"loading-text":"Loading... Please wait","hide-default-footer":false,"calculate-widths":true,"items-per-page":10,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"v-icon notranslate title v-icon--left mdi mdi-label theme--light ma-0",class:item.label,attrs:{"aria-hidden":"true"}})]}},{key:"item.stared",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer d-flex justify-end align-center"},[(!item.stared)?_c('svg',{attrs:{"viewBox":"0 0 24 24","width":"18","height":"18","xmlns":"http://www.w3.org/2000/svg","fill-rule":"evenodd","clip-rule":"evenodd"},on:{"click":function($event){item.stared = !item.stared}}},[_c('path',{attrs:{"fill":"#dfc700","d":"M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z"}})]):_vm._e(),(item.stared)?_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"18","viewBox":"0 0 24 24"},on:{"click":function($event){item.stared = !item.stared}}},[_c('path',{attrs:{"fill":"#dfc700","d":"M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"}})]):_vm._e()])]}},{key:"item.sender",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info"},[_c('p',{staticClass:"mb-0 black--text font-weight-bold"},[_vm._v(_vm._s(item.sender.name))]),_c('small',{staticClass:"d-block black--text font-weight-bold"},[_vm._v(_vm._s(item.sender.email))])])]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-limit-liner mb-0",staticStyle:{"max-width":"370px"}},[_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(_vm._s(item.body.subject))]),_vm._v(" -"),_c('br'),_c('span',{staticClass:"font-weight-light black--text"},[_c('small',[_vm._v(_vm._s(item.body.text))])])]),(item.agent)?_c('p',{staticClass:"mb-0 special-blue font-weight-bold"},[_c('small',[_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('INBOX.DIRECTED')))]),_vm._v(" "),_c('span',{staticClass:"special-blue"},[_vm._v(_vm._s(item.agent))])])]):_vm._e()]}},{key:"item.timer",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 main__info font-weight-bold black--text"},[_c('small',[_vm._v(_vm._s(item.timer))])])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"color":"#D8D8D8","depressed":"","small":""},on:{"click":function($event){return _vm.openMessage(item)}}},[_c('i',{staticClass:"zwicon-eye"})])]}}],null,true)})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label-menu-inbox',{on:{"label-action":_vm.organizeList}}),_c('agents-menu-inbox',{on:{"agent-change":_vm.organizeListByAgent}})],1)],1),(_vm.modalStatus)?_c('InboxModal',{attrs:{"item":_vm.getSelecteItem},on:{"close-me":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }