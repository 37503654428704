import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Inbox action called.`)
  },

  methods: {
    ...mapActions(['getInbox']),

  }
}

export default mixin