<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
      <v-row>
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('INBOX.GENERAL_TITLE') }}</p>
              <p class="page-subtitle"> {{ $t('INBOX.GENERAL_DESCRIPTION') }}</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-card flat>
                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(menu, index) in toolbar"
                                :key="index"
                                class="cursor-pointer mr-10" 
                        >
                            <router-link class="text-decoration-none color-inherit black--text" active-class="white--text"
                                         :to="menu.slug">{{ $t('INBOX.TITLE') }}
                            </router-link>
                        </v-toolbar-title>
                    </v-toolbar>

                    <div>
                        <v-main>
                            <v-data-table
                                    class="table__minimal inbox-email elevation-0"
                                    :loading="dataTableLoading"
                                    :items="getEmails"
                                    :headers="headers"
                                    loading-text="Loading... Please wait"
                                    :hide-default-footer="false"
                                    :calculate-widths="true"
                                    :items-per-page="10"
                                    :item-class="itemRowBackground"
                                    :key="reloadTable"
                            >
                                <template v-slot:[`item.label`]="{ item }">
                                    <i aria-hidden="true"
                                       class="v-icon notranslate title v-icon--left mdi mdi-label theme--light ma-0"
                                       :class="item.label"></i>
                                </template>
                                <template v-slot:[`item.stared`]="{ item }">
                                    <div class="cursor-pointer d-flex justify-end align-center">
                                        <svg @click="item.stared = !item.stared" v-if="!item.stared" viewBox="0 0 24 24"
                                             width="18" height="18" xmlns="http://www.w3.org/2000/svg"
                                             fill-rule="evenodd" clip-rule="evenodd">
                                            <path fill="#dfc700"
                                                  d="M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z"/>
                                        </svg>
                                        <svg @click="item.stared = !item.stared" v-if="item.stared"
                                             xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                             viewBox="0 0 24 24">
                                            <path fill="#dfc700"
                                                  d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
                                        </svg>
                                    </div>
                                </template>
                                <template v-slot:[`item.sender`]="{ item }">
                                    <div class="main__info">
                                        <p class="mb-0 black--text font-weight-bold">{{ item.sender.name }}</p>
                                        <small class="d-block black--text font-weight-bold">{{ item.sender.email }}</small>
                                    </div>
                                </template>
                                <template v-slot:[`item.body`]="{ item }">
                                    <p style="max-width: 370px" class="text-limit-liner mb-0">
                                        <span class="font-weight-bold black--text">{{ item.body.subject }}</span> -<br>
                                        <span class="font-weight-light black--text"><small>{{ item.body.text }}</small></span>
                                    </p>
                                    <p class="mb-0 special-blue font-weight-bold" v-if="item.agent">
                                        <small>
                                            <span style="opacity: 0.5">{{ $t('INBOX.DIRECTED') }}</span> <span class="special-blue">{{ item.agent }}</span></small>
                                    </p>
                                </template>
                                <template v-slot:[`item.timer`]="{ item }">
                                    <p class="mb-0 main__info font-weight-bold black--text">
                                        <small>{{ item.timer }}</small>
                                    </p>
                                </template>
                                <template v-slot:[`item.view`]="{ item }">
                                   <v-btn @click="openMessage(item)" color="#D8D8D8" class="pa-0 ma-0" depressed small>
                                      <i class="zwicon-eye"></i>
                                  </v-btn>
                                </template>
                            </v-data-table>
                        </v-main>
                    </div>

                </v-card>
            </v-col>

            <v-col cols="4">
                <label-menu-inbox @label-action="organizeList"/>
                <agents-menu-inbox @agent-change="organizeListByAgent" />
            </v-col>
        </v-row>
        <InboxModal v-if="modalStatus" :item="getSelecteItem" @close-me="closeModal"/>
    </v-container>
</template>

<script>
  import mixin from '@/models/inbox/InboxModel'
  
  export default {
    name: 'Inbox',
    mixins: [mixin],
    async created(){
      await this.getInbox()
    },
    data: () => ({
      dataTableLoading: false,
      modalStatus: false,
      selectedItem: null,
      filter: 'All',
      filterByAgent: [],
      reloadTable: 0, 
      toolbar: [
        { name: 'Inbox', slug: 'inbox' },
      ],
      headers: [
        { text: 'Icon', value: 'stared', width: '5%', align: 'left' },
        { value: 'label', width: '5%', align: 'left' },
        { value: 'sender', width: '5%', align: 'left' },
        { value: 'body', align: 'left', width: '60%'},
        { value: 'timer', width: '20%', align: 'start' },
        { value: 'view', width: '10%', align: 'img' }
      ],
      
    }),

    methods: {
      closeModal(){
        this.modalStatus = false
        this.selectedItem = null
      },
      openMessage(item){
        this.modalStatus = true
        this.selectedItem = item
      },
      itemRowBackground: function(item) {
        return item.read ? 'light-bg-light' : ''
      },
      getMessageStruct(){
        let message = {
          label: '',
          sender: {
            name: '',
            email: ''
          },
          body: {
            subject: '',
            text: ''
          },
          timer: '',
          read: null,
          stared: null,
          agent: ''
        }
        return message
      },
      // CONTACT
      organizeBookVisit(arrayMessage, b){

        if(b==undefined)
          return []

        b.forEach(element => {      
          let message = this.getMessageStruct()
          message.label = 'yellow--text text--lighten-2'
          message.sender.name = element.first_name + ' ' + element.last_name
          message.sender.email = element.email
          
          if(element.block == "condominiums")
            message.body.subject = 'Book Visit New Development: ' + element.reference
          else
            message.body.subject = 'Book Visit Property: ' + element.reference

          message.body.text = element.message
          message.timer = element.created_at
          message.read = false
          message.stared = true
          message.agent = element.sent_to

          arrayMessage.push(message)
        });

        return arrayMessage;
      },
      // INFORMATION
      organizeFeedback(arrayMessage, b){
        let message = this.getMessageStruct()
        
        if(b==undefined)
          return []

        b = Object.keys(b).map(k =>
          b[k]
        )

        b.forEach(element => {
          message.label = 'green--text text--lighten-2'
          message.sender.name = element.first_name + ' ' + element.last_name
          message.sender.email = element.email
          message.body.subject = 'Zoom us'
          message.body.text = element.message
          message.timer = element.created_at
          message.read = false
          message.stared = false
          message.agent = element.sent_to

          arrayMessage.push(message)
        });


        return arrayMessage;
      },

      organizeSellWithUs(arrayMessage, b){
        let message = this.getMessageStruct()
        
        if(b==undefined)
          return []

        b = Object.keys(b).map(k =>
          b[k]
        )

        b.forEach(element => {
          message.label = 'purple--text text--lighten-2' 
          message.sender.name = element.first_name + ' ' + element.last_name
          message.sender.email = element.email
          message.body.subject = 'Sell with us'
          message.body.text = element.message
          message.timer = element.created_at
          message.read = false
          message.stared = false
          message.agent = element.sent_to

          arrayMessage.push(message)
        });


        return arrayMessage;
      },

      organizeVineyards(arrayMessage, b){
        let message = this.getMessageStruct()
        
        if(b==undefined)
          return []

        b = Object.keys(b).map(k =>
          b[k]
        )

        b.forEach(element => {
          message.label = 'orange--text text--lighten-2'
          message.sender.name = element.name
          message.sender.email = element.email
          message.body.subject = 'Vineyard Contact'
          message.body.text = "Looking for: " + element.looking_for + " at region: " + element.region
          message.timer = element.created_at
          message.read = false
          message.stared = false
          message.agent = element.sent_to

          arrayMessage.push(message)
        });


        return arrayMessage;
      },
      // CONTACT
      organizeWorkWithMe(arrayMessage, b){
        let message = this.getMessageStruct()
        
        if(b==undefined)
          return []

        b = Object.keys(b).map(k =>
          b[k]
        )

        b.forEach(element => {
          message.label = 'red--text text--lighten-2'
          message.sender.name = element.name
          message.sender.email = element.email
          message.body.subject = 'Work with me'
          message.body.text = "Idea: " + element.idea + " (Phone: " + element.phone + ")"
          message.timer = element.created_at
          message.read = false
          message.stared = false
          message.agent = element.sent_to

          arrayMessage.push(message)
        });


        return arrayMessage;
      },
      organizeList(action){
        this.filter = action
        this.reloadTable++
      },
      organizeListByAgent(list){
        this.filterByAgent = list

        this.reloadTable++
      },
      organizeAgentList(arrayMessage){
        let messagesArray = []


        arrayMessage.forEach(message => {
          this.filterByAgent.forEach(agent => {
            if(message.agent != null)
              if(message.agent.includes(agent))
                messagesArray.push(message)
          });
        });
        return messagesArray
      }
    },
    computed:{
      getSelecteItem(){
        return this.selectedItem
      },
      getEmails(){
        let serverEmails = this.$store.state.inboxModule.inbox
        // Get Book Visit messages
        let bookVisit = serverEmails.bookVisit
        let feedback = serverEmails.feedback
        let sellWithUs = serverEmails.sellWithUs
        let vineyards = serverEmails.vineyards
        let workwithme = serverEmails.workWithMe
        let arrayMessage = []

        switch(this.filter){
          case 'All':
            arrayMessage = this.organizeBookVisit(arrayMessage, bookVisit)
            arrayMessage = this.organizeFeedback(arrayMessage, feedback)
            arrayMessage = this.organizeSellWithUs(arrayMessage, sellWithUs)
            arrayMessage = this.organizeVineyards(arrayMessage, vineyards)
            arrayMessage = this.organizeWorkWithMe(arrayMessage, workwithme)
            break;
          case 'bookVisit':
            arrayMessage = this.organizeBookVisit(arrayMessage, bookVisit)
            break
          case 'zoomUs':
            arrayMessage = this.organizeFeedback(arrayMessage, feedback)
            break
          case 'sellWithUs':
            arrayMessage = this.organizeSellWithUs(arrayMessage, sellWithUs)
            break
          case 'vineyardsForm':
            arrayMessage = this.organizeVineyards(arrayMessage, vineyards)
            break
          case 'workWithMe':
            arrayMessage = this.organizeWorkWithMe(arrayMessage, workwithme)
            break
        }

        if(this.filterByAgent.length != 0)
          arrayMessage = this.organizeAgentList(arrayMessage)
        
        return arrayMessage
      }
    }
  }
</script>
